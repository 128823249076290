import React, { useContext, useEffect, useState } from "react";

import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import PlayerContext from "./../../hooks/PlayerContext";
import CMCDContext from "../../hooks/CMCDContext";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

export function CMCDInfo() {
  const [{ player }] = useContext(PlayerContext);
  const [{ cmcdData }] = useContext(CMCDContext);
  const [data, setData] = useState(null);
  const [wholeData, setWholeData] = useState(null);
 // const [count, setCount] = useState(0);

  useEffect(() => {
    if (!cmcdData) {
        return;
    }
    const description = {
      br: "Encoded Bitrate",
      bl: "Buffer Length",
      bs: "Buffer starvation",
      cid: "Content ID",
      d: "Object duration",
      dl: "Deadline",
      mtp: "Measured throughput",
      nor: "Next object request",
      nrr: "Next range request",
      ot: "Object type",
      pr: "Playback rate",
      rtp: "Requested maximum throughput",
      sf: "Streaming format",
      sid: "Session ID",
      st: "Stream type",
      su: "Startup",
      tb: "Top bitrate",
      v: "CMCD version"
  }
    let keys = Object.keys(cmcdData);
    // keys = keys?.sort();
    let detail = "";
    for (const key of keys) {
        detail += description[key].padEnd(4) + ': ' + cmcdData[key] + '\n';
    }
    detail += "-------------------------------\n\n";
    setData(detail)
  }, [cmcdData, data]);

  useEffect(() => {
    if (!data) {
        return;
    }
    setWholeData(wholeData => wholeData? wholeData.concat(data) : data);
   //  setCount(count => count+1);
  }, [data]);

  /*setCount(() => {
    if (count >= 10) {
      setCount(0);
      setWholeData(null);
    }
  }, [count])*/

  return player && cmcdData ? (
    <div>
        <br />
        <Table>
            <TableHead>
            <TableRow>
                <TableCell>CMCD</TableCell>
            </TableRow>
            </TableHead>
        </Table>
        <TextareaAutosize
            defaultValue={wholeData}
            style={{ width: 400 }}
            rowsMax={20}
        />
    </div>
  ) : null;
}
