import React from "react";

import { AudioDebugInfo } from "./AudioDebugInfo";
import { VideoDebugInfo } from "./VideoDebugInfo";
import { CMCDInfo } from "./CMCDInfo";
import { ChartLegend } from "./ChartLegend";

import Grid from "@material-ui/core/Grid";

import "./DebugInfo.scss";

export function DebugInfo() {
  return (
    <Grid container className={"debug-info-wrapper"}>
      <Grid item lg={6} sm={6} md={10} className={"debug-video-wrapper"}>
        <VideoDebugInfo />
      </Grid>
      <Grid item lg={5} sm={5} md={10} className={"debug-audio-wrapper"}>
        <AudioDebugInfo />
        <ChartLegend />
        <CMCDInfo />
      </Grid>
    </Grid>
  );
}
