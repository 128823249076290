import React, { useCallback, useContext, useEffect, useState } from "react";
import PlayerContext from "./PlayerContext";

const DebugInfoContext = React.createContext();

export function DebugInfoProvider({ children }) {
  const [{ player }] = useContext(PlayerContext);

  const getVideoCodec = useCallback(() => {
    const metaData = player?.getMetaData();
    const codec = metaData?.vcodec || "";
    const transcoderCodec = metaData?.transcoder?.videoCodec;
    return `${codec}${transcoderCodec ? `- (${transcoderCodec})` : ""}`;
  }, [player]);

  const getCurrentBitrate = useCallback(() => {
    const allBitrates = player?.getQualityLevels();
    const currentBitrateIdx = player?.getCurrentQuality() || 0;

    return allBitrates?.length > 0 && allBitrates[currentBitrateIdx];
  }, [player]);

  const getRequestedChunks = useCallback(() => {
    const vMovingMetrics = player?.getMovingMetrics()?.video;
    if (vMovingMetrics) {
      return vMovingMetrics.requestedChunk;
    }

    if (player?.getPlayState() !== "buffering") {
      return player?.getRequestedChunks();
    }

    return null;
  }, [player]);

  const getMovingDetails = useCallback(
    (type) => {
      const movingMetrics = player?.getMovingMetrics()?.[type];
      if (movingMetrics) {
        return {
          latency:
            movingMetrics.latency?.low.toFixed(2) +
            " | " +
            movingMetrics.latency?.average.toFixed(2) +
            " | " +
            movingMetrics.latency?.high.toFixed(2),
          download:
            movingMetrics.download?.low.toFixed(2) +
            " | " +
            movingMetrics.download?.average.toFixed(2) +
            " | " +
            movingMetrics.download?.high.toFixed(2),
          ratio:
            movingMetrics.ratio?.low.toFixed(2) +
            " | " +
            movingMetrics.ratio?.average.toFixed(2) +
            " | " +
            movingMetrics.ratio?.high.toFixed(2),
        };
      }
      return null;
    },
    [player]
  );

  const getAudioDebugInfo = useCallback(() => {
    return {
      codec: player?.getMetaData()?.acodec,
      dataRate: player?.getAudioDatarate(),
      device: player?.getAudioDevice(),
      sampleRate: player?.getAudioSampleRate(),
      audioMovingMetrics: player?.getMovingMetrics()?.audio,
      movingAudioDetails: getMovingDetails("audio"),
    };
  }, [player, getMovingDetails]);

  const getVideoDebugInfo = useCallback(() => {
    return {
      actualBitrate: player?.getActualBitrate(),
      bandwidth: player?.getBandwidth(),
      desiredBufferTime:
        player?.getActiveVideoHandler() === "RTC"
          ? null
          : player?.getPlayerOptions().bufferTime,
      actualBufferLength: player?.getActualBuffer(),
      bfr: player?.getBufferRelated()?.bufferFillRate,
      codec: getVideoCodec(),
      codecProfile: player?.getMetaData()?.transcoder?.videoProfile,
      decodingState: player?.getVideoDecodingState(),
      renderState: player?.getVideoRenderState(),
      fps: player?.getFPS(),
      resolution: player?.getResolution(),
      keyframeFrequency: player?.getKeyframeFreq(),
      edgeServerIp: player?.getEdgeServerIp(),
      droppedFrames: player?.getFpsDroppedFrames() || "0",
      httpLatency: player?.getHttpLatency(),
      currentBitrateIdx: player?.getCurrentQuality(),
      allBitrates: player?.getQualityLevels(),
      currentBitrate: getCurrentBitrate(),
      playingChunk: player?.getPlayingChunk(),
      requestedChunks: getRequestedChunks(),
      videoMovingMetrics: player?.getMovingMetrics()?.video,
      movingVideoDetails: getMovingDetails("video"),
      currentTime: player?.getCurrentTime(),
      liveEdge: player?.getLiveEdge(),
      pendingIndex:
        player?.getDownloadingIndex() || player?.getCurrentQuality() + 1,
      maxIndex: player?.getQualityLevels()?.length || 0,
      videoDataRate: player?.getVideoDataRate() || null,
      liveLatency: player?.getLiveLatency(),
    };
  }, [
    player,
    getVideoCodec,
    getCurrentBitrate,
    getRequestedChunks,
    getMovingDetails,
  ]);

  const [audioDebugInfo, setAudioDebugInfo] = useState(getAudioDebugInfo());
  const [videoDebugInfo, setVideoDebugInfo] = useState(getVideoDebugInfo());
  const [handler, setHandler] = useState(null);

  useEffect(() => {
    if (player) {
      player.once("handlerLoaded", () => {
        setHandler(player.getActiveVideoHandler());
      });

      return () => player.off("handlerLoaded");
    }
  }, [player]);

  useEffect(() => {
    if (player) {
      setAudioDebugInfo(getAudioDebugInfo());
      setVideoDebugInfo(getVideoDebugInfo());

      const interval = setInterval(() => {
        setAudioDebugInfo(getAudioDebugInfo());
        setVideoDebugInfo(getVideoDebugInfo());
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [player, getAudioDebugInfo, getVideoDebugInfo]);

  return (
    <DebugInfoContext.Provider
      value={[{ audioDebugInfo, videoDebugInfo, handler }]}
    >
      {children}
    </DebugInfoContext.Provider>
  );
}

export default DebugInfoContext;
