import React, { useContext } from "react";

import DebugInfoContext from "./../../hooks/DebugInfoContext";
import PlayerContext from "./../../hooks/PlayerContext";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { DetailsRow } from "./DetailsRow";

import "./DebugInfo.scss";

export function AudioDebugInfo() {
  const [{ audioDebugInfo }] = useContext(DebugInfoContext);
  const [{ player }] = useContext(PlayerContext);

  return player ? (
    <TableContainer component={Paper} className={"table-container"}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Audio Information</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <DetailsRow title={"Audio Codec"} value={audioDebugInfo.codec} />
          <DetailsRow
            title={"Audio datarate"}
            value={audioDebugInfo.dataRate}
          />
          <DetailsRow title={"Audio device"} value={audioDebugInfo.device} />
          <DetailsRow
            title={"Audio samplerate"}
            value={audioDebugInfo.sampleRate}
          />
          <DetailsRow
            title={"Latency (min|avg|max)"}
            value={audioDebugInfo.movingAudioDetails?.latency}
            tooltip={
              "The minimum, average and maximum latency over the last 4 requested segments. Latency is the time in seconds from request of segment to receipt of first byte"
            }
          />
          <DetailsRow
            title={"Download (min|avg|max)"}
            value={audioDebugInfo.movingAudioDetails?.download}
            tooltip={
              "The minimum, average and maximum download time for the last 4 requested segments. Download time is the time in seconds from first byte being received to the last byte"
            }
          />
          <DetailsRow
            title={"Ratio (min|avg|max)"}
            value={audioDebugInfo.movingAudioDetails?.ratio}
            tooltip={
              "The minimum, average and maximum ratio of the segment playback time to total download time over the last 4 segments"
            }
          />
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
}
