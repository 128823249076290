import "./App.scss";
import { SettingsPanel } from "./../components/SettingsPanel/SettingsPanel";
import { AllSettings } from "./../components/AllSettings/AllSettings";
import { StreamLoad } from "./../components/StreamLoad/StreamLoad";
import { Player } from "./../components/Player/Player";
import { DebugInfo } from "./../components/DebugInfo/index";
import { AllCharts } from "./../components/AllCharts/AllCharts";

import Grid from "@material-ui/core/Grid";

function App() {
  return (
    <div className="App">
      <Grid container className="input-holder">
        <Grid item lg={5} md={6} sm={12}>
          <SettingsPanel />
        </Grid>
        <StreamLoad />
      </Grid>
      <AllSettings />
      <Grid container className="top-section">
        <Grid item lg={6} md={5} sm={12}>
          <DebugInfo />
        </Grid>
        <Grid item lg={6} md={7} sm={12}>
          <Player />
        </Grid>
      </Grid>
      <AllCharts />
    </div>
  );
}

export default App;
