import React, { useContext } from "react";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { DetailsRow } from "./DetailsRow";

import PlayerContext from "./../../hooks/PlayerContext";

export function ChartLegend() {
  const [{ player }] = useContext(PlayerContext);

  return player ? (
    <TableContainer component={Paper} className={"legend-table-container"}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Chart Legend</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <DetailsRow
            title={"current value"}
            value={<hr className="current-line" />}
          />
          <DetailsRow
            title={"target value"}
            value={<hr className="target-line" />}
          />
          <DetailsRow
            title={"30 second max/min"}
            value={<hr className="timeframe-line" />}
          />
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
}
