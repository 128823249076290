import React, { useContext } from "react";
import SettingsIcon from "@material-ui/icons/SettingsApplicationsOutlined";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import NumericInput from "react-numeric-input";

import SettingsContext from "./../../hooks/SettingsContext";

import "./SettingsPanel.scss";

export function SettingsPanel() {
  const [
    { showSettings, desiredBuffer, streamType },
    { setShowSettings, setDesiredBuffer, setStreamType },
  ] = useContext(SettingsContext);

  return (
    <Grid container className={"settings-panel"}>
      <div
        className={"settings-icon"}
        onClick={() => setShowSettings(!showSettings)}
      >
        <SettingsIcon
          fontSize={"large"}
          color={showSettings ? "primary" : "inherit"}
        />
      </div>
      <div className={"buffer-input-wrapper"}>
        <p>Buffer Length:</p>
        <NumericInput
          className={"buffer-input"}
          strict
          size={40}
          min={0}
          max={100}
          value={desiredBuffer}
          step={0.1}
          onChange={(num) => setDesiredBuffer(num)}
        />
      </div>
      <div className={"type-input-wrapper"}>
        <p>Choose media type:</p>
        <Select
          autoWidth
          value={streamType}
          className={"stream-type"}
          onChange={(event) => setStreamType(event.target.value)}
          variant={"outlined"}
        >
          <MenuItem value={"Auto"}>Auto</MenuItem>
          <MenuItem value={"DASH"}>DASH</MenuItem>
          <MenuItem value={"HLS"}>HLS</MenuItem>
          <MenuItem value={"RTMP"}>RTMP</MenuItem>
          <MenuItem value={"SMIL"}>SMIL</MenuItem>
          <MenuItem value={"RTC"}>RTC</MenuItem>
        </Select>
      </div>
    </Grid>
  );
}
