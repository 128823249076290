import React, { useContext, useLayoutEffect } from "react";

import SettingsContext from "./../../hooks/SettingsContext";
import PlayerContext from "./../../hooks/PlayerContext";

import Grid from "@material-ui/core/Grid";

import "./Player.scss";

export function Player() {
  const [{ streamType, streamUrl }] = useContext(SettingsContext);
  const [{ player }, { setPlayer }] = useContext(PlayerContext);

  const getMimeType = (streamType) => {
    const type = streamType ? streamType.toUpperCase() : "";
    switch (type) {
      case "DASH":
        return "application/dash+xml";
      case "HLS":
        return "application/x-mpegURL";
      case "SMIL":
        return "application/smil+xml";
      case "RTMP":
        return "video/rtmp";
      case "RTC":
        return "rtc";
      default:
        return "";
    }
  };

  useLayoutEffect(() => {
    if (player !== undefined) {
      if (streamUrl === null) {
        player.remove();
        setPlayer(undefined);

        return;
      }

      const mimeType = getMimeType(streamType);
      player.load(streamUrl, mimeType);
    }
  }, [player, setPlayer, streamUrl, streamType]);

  return (
    <Grid container className={"player-wrapper"}>
      <div id={"avvpl-player-container"} />
    </Grid>
  );
}
