import React, { useContext, useEffect, useState } from "react";
import PlayerContext from "./PlayerContext";

const CMCDContext = React.createContext();

export function CMCDProvider({ children }) {
  const [{ player }] = useContext(PlayerContext);
  const [cmcdData, setCmcdData] = useState(null);

  useEffect(() => {
    if (player) {
      player.on("cmcdDataSent", (cmcdData) => {
        setCmcdData(cmcdData)
      });

      return () => player.off("cmcdDataSent");
    }
  }, [player]);

  return (
    <CMCDContext.Provider
      value={[{ cmcdData }]}
    >
      {children}
    </CMCDContext.Provider>
  );
}

export default CMCDContext;
