import TableCell from "@material-ui/core/TableCell";
import { TableRow } from "@material-ui/core";

export function DetailsRow({ title, value, unit, precision, tooltip }) {
  return value ? (
    <TableRow title={tooltip}>
      <TableCell
        className={"table-cell"}
        component="th"
        scope="row"
        size="small"
      >
        {title}
      </TableCell>
      <TableCell className={"table-cell"} align="left" size="small">
        {precision ? value?.toFixed(precision) : value} {unit}
      </TableCell>
    </TableRow>
  ) : null;
}
