import React, { useContext } from "react";

import Chip from "@material-ui/core/Chip";
import CloseIcon from "@material-ui/icons/CloseRounded";
import DoneIcon from "@material-ui/icons/DoneRounded";
import Collapse from "@material-ui/core/Collapse";

import SettingsContext from "./../../hooks/SettingsContext";
import "./AllSettings.scss";

export function AllSettings() {
  const [{ showSettings, settings }, actions] = useContext(SettingsContext);

  function SettingsChip({ setting, label }) {
    return (
      <Chip
        className={"settings-chip"}
        variant="outlined"
        icon={settings[setting] ? <DoneIcon /> : <CloseIcon />}
        color={settings[setting] ? "secondary" : "primary"}
        label={label}
        onClick={() => actions.toggleSettingValue(setting)}
      />
    );
  }

  return (
    <Collapse in={showSettings}>
      <div className={"all-settings"}>
        <SettingsChip
          setting={"fmsAppInstanceIncluded"}
          label={"Includes FMS Application Instance"}
        />
        <SettingsChip
          setting={"hwAccelerationDisabled"}
          label={"Disable hardware acceleration"}
        />
        <SettingsChip
          setting={"useBufferFromManifest"}
          label={"Use buffer recommended by manifest (DASH only)"}
        />
        <SettingsChip
          setting={"trackLiveEdge"}
          label={"Reach buffer edge when too far from live edge"}
        />
        <SettingsChip
          setting={"enableLowLatency"}
          label={"Enable low latency (CMAF)"}
        />
        <SettingsChip
          setting={"useDefaults"}
          label={"Use defaults suggested by hlsjs/dashjs"}
        />
        <SettingsChip setting={"enableDVR"} label={"Enable DVR"} />
        <SettingsChip
          setting={"enableMultiLanguage"}
          label={"Enable multiple audio-languages from manifest"}
        />
      </div>
    </Collapse>
  );
}
