import React, { useContext, useEffect, useState, useCallback } from "react";
import LoadContext from "./LoadContext";
import SettingsContext from "./SettingsContext";

const PlayerContext = React.createContext();

export function PlayerProvider({ children }) {
  const [{ settings, desiredBuffer }] = useContext(SettingsContext);
  const [{ readyToLoad }] = useContext(LoadContext);

  const getConfigValues = useCallback(() => {
    return {
      id: "avvpl-player-container",
      handlers: ["html5", "hls", "dash", "flash", "rtc"],
      trackLiveEdge: settings.trackLiveEdge,
      bufferTime: desiredBuffer,
      useSuggestedPresentationDelay: settings.useBufferFromManifest,
      swf: "avvpl-marvin/dist/srplayer.swf",
      edgeServerIpDetection: true,
      loglevel: 3,
      mute: true,
      skin: {
        theme: 0,
      },
      enableKeyboardShortcuts: false,
      enableCmaf: settings.enableLowLatency,
      hardwareAcceleration: settings.hwAccelerationDisabled,
      useHlsDefaults: settings.useDefaults,
      useDashDefaults: settings.useDefaults,
      includesAppInstance: settings.fmsAppInstanceIncluded,
      allowDVR: settings.enableDVR,
      enableMultiLanguage: settings.enableMultiLanguage,
      videoid: settings.videoId,
      enableCmcd: settings.enableCmcd,
    };
  }, [settings, desiredBuffer]);

  const [config, setConfig] = useState(getConfigValues());
  const [player, setPlayer] = useState(undefined);

  useEffect(() => {
    setConfig(getConfigValues());
  }, [settings, getConfigValues]);

  useEffect(() => {
    if (!player && readyToLoad) {
      setPlayer(new window.avvpl.setupPlayer(config));
    }
  }, [config, readyToLoad, player, setPlayer]);

  return (
    <PlayerContext.Provider
      value={[
        { player, config },
        { setPlayer, setConfig },
      ]}
    >
      {children}
    </PlayerContext.Provider>
  );
}

export default PlayerContext;
