import React, { useContext, useState } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import SettingsContext from "./../../hooks/SettingsContext";
import PlayerContext from "../../hooks/PlayerContext";
import LoadContext from "../../hooks/LoadContext";

import Grid from "@material-ui/core/Grid";

import "./StreamLoad.scss";

export function StreamLoad() {
  const [{ streamUrl }, { setStreamUrl }] = useContext(SettingsContext);
  const [{ player }, { setPlayer }] = useContext(PlayerContext);
  const [, { setReadyToLoad }] = useContext(LoadContext);
  const [url, setUrl] = useState("");

  const onLoadClick = () => {
    if (player) {
      player.remove();
      setPlayer(undefined);
    }
    setStreamUrl(url);
    setReadyToLoad(true);
  };

  const onKeyDown = (evt) => {
    if (evt.keyCode === 13) {
      onLoadClick();
   }
  };

  return (
    <Grid item lg={6} md={6} sm={12} className={"stream-load-wrapper"}>
      <div className={"load-button"}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => onLoadClick()}
        >
          LOAD
        </Button>
      </div>
      <div className={"url-input-wrapper"}>
        <TextField
          id="stream-url"
          value={url || streamUrl}
          placeholder="Enter stream url..."
          variant="outlined"
          fullWidth
          size="small"
          onChange={(evt) => setUrl(evt.target.value)}
          onKeyDown={(evt) => onKeyDown(evt)}
        />
      </div>
    </Grid>
  );
}
