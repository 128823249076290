import React, { useContext } from "react";

import DebugInfoContext from "./../../hooks/DebugInfoContext";
import PlayerContext from "./../../hooks/PlayerContext";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { DetailsRow } from "./DetailsRow";

import "./DebugInfo.scss";

export function VideoDebugInfo() {
  const [{ videoDebugInfo }] = useContext(DebugInfoContext);
  const [{ player }] = useContext(PlayerContext);

  function getSelectedBitrate(currentBitrate, bitrates) {
    return bitrates?.length > 0
      ? bitrates?.map((bitrate) => (
          <span
            key={bitrate}
            className={
              bitrate === currentBitrate ? "current-bitrate" : "other-bitrate"
            }
          >
            {`${bitrate} `}
          </span>
        ))
      : null;
  }

  function getSegmentDetails(playingChunk, requestedChunks) {
    const currentChunk = playingChunk?.chunk || playingChunk;
    const currentQlty = playingChunk?.qlty;
    let allChunks = [];
    requestedChunks?.map((reqChunk, idx) => {
      reqChunk?.qlty
        ? allChunks.push({
            value: reqChunk.chunk,
            bandwidth: reqChunk.qlty,
            active:
              reqChunk.chunk === currentChunk && reqChunk.qlty === currentQlty,
            index: idx,
          })
        : allChunks.push({
            value: reqChunk,
            bandwidth: "",
            active: reqChunk === currentChunk,
            index: idx,
          });
      return allChunks;
    });
    if (allChunks.length > 100) {
      const filteredChunks = allChunks.filter((chunk) => chunk.active)[0];
      const activeIndex = filteredChunks?.index;
      allChunks = allChunks.slice(activeIndex, activeIndex + 100);
    }
    return allChunks.length > 0
      ? allChunks?.map((segment, idx) => (
          <div
            key={idx}
            className={segment.active ? "current-segment" : "other-segment"}
          >
            {`${segment.value}${
              segment.bandwidth ? `@${segment.bandwidth}` : ""
            }`}
          </div>
        ))
      : null;
  }

  function getLiveEdgeDetails(currentTime, liveEdge, desiredBuffer) {
    const notInLiveEdge =
      currentTime && liveEdge && liveEdge - currentTime > 1.5 * desiredBuffer;
    return currentTime && liveEdge ? (
      <span className={notInLiveEdge ? "not-live-edge" : "live-edge"}>
        {`${currentTime.toFixed(2)} / ${liveEdge.toFixed(2)}`}
      </span>
    ) : null;
  }

  function getIndexDetails(pendingIndex, maxIndex) {
    return pendingIndex && maxIndex && `${pendingIndex}/${maxIndex}`;
  }

  return player ? (
    <TableContainer component={Paper} className={"table-container"}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell size="medium">Video Information</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <DetailsRow
            title={"Selected bitrate (MBR)"}
            value={getSelectedBitrate(
              videoDebugInfo.currentBitrate,
              videoDebugInfo.allBitrates
            )}
            unit={"kbps"}
          />
          <DetailsRow
            title={"Actual bitrate"}
            value={videoDebugInfo.actualBitrate}
            unit={"kbps"}
            precision={2}
          />
          <DetailsRow
            title={"Current Index / Total Index"}
            value={getIndexDetails(
              videoDebugInfo.pendingIndex,
              videoDebugInfo.maxIndex
            )}
          />
          <DetailsRow
            title={"Bandwidth"}
            value={videoDebugInfo.bandwidth}
            unit={"kbps"}
          />
          <DetailsRow
            title={"Desired buffer length"}
            value={videoDebugInfo.desiredBufferTime}
            unit={"seconds"}
            precision={2}
          />
          <DetailsRow
            title={"Actual buffer length"}
            value={videoDebugInfo.actualBufferLength}
            unit={"seconds"}
            precision={2}
          />
          <DetailsRow title={"Buffer fill rate"} value={videoDebugInfo.bfr} />
          <DetailsRow
            title={"Live latency"}
            value={videoDebugInfo.liveLatency}
            unit={"ms"}
            precision={2}
          />
          <DetailsRow
            title={"Latency (min|avg|max)"}
            value={videoDebugInfo.movingVideoDetails?.latency}
            tooltip={
              "The minimum, average and maximum latency over the last 4 requested segments. Latency is the time in seconds from request of segment to receipt of first byte"
            }
          />
          <DetailsRow
            title={"Download (min|avg|max)"}
            value={videoDebugInfo.movingVideoDetails?.download}
            tooltip={
              "The minimum, average and maximum download time for the last 4 requested segments. Download time is the time in seconds from first byte being received to the last byte"
            }
          />
          <DetailsRow
            title={"Ratio (min|avg|max)"}
            value={videoDebugInfo.movingVideoDetails?.ratio}
            tooltip={
              "The minimum, average and maximum ratio of the segment playback time to total download time over the last 4 segments"
            }
          />
          <DetailsRow
            title={"Dropped frames"}
            value={videoDebugInfo.droppedFrames}
          />
          <DetailsRow title={"Video codec"} value={videoDebugInfo.codec} />
          <DetailsRow
            title={"Video codec profile"}
            value={videoDebugInfo.codecProfile}
          />
          <DetailsRow
            title={"Video decoding"}
            value={videoDebugInfo.decodingState}
          />
          <DetailsRow
            title={"Video renderer"}
            value={videoDebugInfo.renderState}
          />
          <DetailsRow
            title={"FPS"}
            value={videoDebugInfo.fps}
            precision={isNaN(videoDebugInfo.fps) ? null : 2}
          />
          <DetailsRow title={"Resolution"} value={videoDebugInfo.resolution} />
          <DetailsRow
            title={"Keyframe frequency"}
            value={videoDebugInfo.keyframeFrequency}
          />
          <DetailsRow
            title={"Edge server IP"}
            value={videoDebugInfo.edgeServerIp}
          />
          <DetailsRow
            title={"Current Time / Live Edge"}
            value={getLiveEdgeDetails(
              videoDebugInfo.currentTime,
              videoDebugInfo.liveEdge,
              videoDebugInfo.desiredBufferTime
            )}
          />
          <DetailsRow
            title={"Playing Segment Detail"}
            value={getSegmentDetails(
              videoDebugInfo.playingChunk,
              videoDebugInfo.requestedChunks
            )}
          />
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
}
