import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import LoadContext from "./LoadContext";

const SettingsContext = React.createContext();

export function SettingsProvider({ children }) {
  const location = useLocation();
  const useQuery = new URLSearchParams(location.search);
  const [, { setReadyToLoad }] = useContext(LoadContext);

  const getBufferTimeFromStreamType = (streamType) => {
    let type = streamType ? streamType.toUpperCase() : "";
    switch (type) {
      case "DASH":
      case "HLS":
      case "SMIL":
        return parseFloat(3);
      case "RTMP":
        return parseFloat(0.5);
      case "RTC":
        return parseFloat(0.5);
      default:
        return parseFloat(3);
    }
  };

  const getStreamUrl = (url) => {
    let streamUrl = url?.replace("-", "/") || "";
    if (streamUrl) {
      try {
        streamUrl = atob(streamUrl);
      } catch (error) {
        console.log(
          new Date().toTimeString() +
            ": [ERROR][DEBUG-PLAYER-AVVPL] " +
            error.message.toUpperCase()
        );
        streamUrl = "";
      }
    }
    return streamUrl;
  };

  const initialSettings = {
    fmsAppInstanceIncluded:
      useQuery.get("includesApplicationInstance") === "1" ? true : false,
    hwAccelerationDisabled: false,
    useBufferFromManifest:
      useQuery.get("useSuggestedPresentationDelay") === "1" ? true : false,
    trackLiveEdge: useQuery.get("trackLiveEdge") === "1" ? true : false,
    enableLowLatency: useQuery.get("enableCmaf") === "1" ? true : false,
    useDefaults: useQuery.get("useDefaults") === "1" ? true : false,
    enableDVR: useQuery.get("allowDVR") === "1" ? true : false,
    enableMultiLanguage:
      useQuery.get("enableMultiLanguage") === "1" ? true : false,
    videoId: useQuery.get("videoId") || null,
    enableCmcd: useQuery.get("enableCmcd") === "1" ? true : false
  };
  const initialDesiredBuffer =
    useQuery.get("bufferTime") ||
    getBufferTimeFromStreamType(useQuery.get("type"));
  const initialStreamType = useQuery.get("type")
    ? useQuery.get("type").toUpperCase()
    : "Auto";
  const initialStreamUrl = getStreamUrl(location.pathname.replace("/avvpl-new-debug-player", "").replace("/", ""));
  const [settings, updateSettings] = useState(initialSettings);
  const [showSettings, setShowSettings] = useState(false);
  const [desiredBuffer, setDesiredBuffer] = useState(
    Number(initialDesiredBuffer)
  );
  const [streamType, setStreamType] = useState(initialStreamType);
  const [streamUrl, setStreamUrl] = useState(initialStreamUrl);

  useEffect(() => {
    if (initialStreamUrl) {
      setReadyToLoad(true);
    }
  }, [initialStreamUrl, setReadyToLoad]);

  const toggleSettingValue = (setting) => {
    updateSettings({
      ...settings,
      [setting]: !settings[setting],
    });
  };

  return (
    <SettingsContext.Provider
      value={[
        { showSettings, settings, desiredBuffer, streamType, streamUrl },
        {
          setShowSettings,
          updateSettings,
          toggleSettingValue,
          setDesiredBuffer,
          setStreamType,
          setStreamUrl,
        },
      ]}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export default SettingsContext;
