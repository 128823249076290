import React, { useContext, useEffect, useState } from "react";

import DebugInfoContext from "./../../hooks/DebugInfoContext";

import { LineChart } from "../LineChart/LineChart";

import Grid from "@material-ui/core/Grid";
import { Tooltip } from "@material-ui/core";

import "./AllCharts.scss";

export function AllCharts() {
  const [{ videoDebugInfo, handler }] = useContext(DebugInfoContext);
  const [bufferLength, setBufferLength] = useState(null);
  const [fps, setFps] = useState(null);
  const [rtt, setRTT] = useState(null);
  const [idealLatency, setIdealLatency] = useState(null);
  const [bandwidth, setBandwidth] = useState(null);
  const [delayFromLiveEdge, setDelayFromLiveEdge] = useState(null);
  const [avgMovingRatio, setAvgMovingRatio] = useState(null);
  const [desiredBufferTime, setDesiredBufferTime] = useState(null);
  const [idealBandwidth, setIdealBandwidth] = useState(null);

  useEffect(() => {
    setBufferLength(videoDebugInfo.actualBufferLength);
    setFps(videoDebugInfo.fps);
    setRTT(
      videoDebugInfo.videoMovingMetrics?.latency?.average ||
        videoDebugInfo.httpLatency
    );
    setBandwidth(videoDebugInfo.bandwidth);
    setDelayFromLiveEdge(
      videoDebugInfo.liveEdge - videoDebugInfo.currentTime || 0
    );
    setAvgMovingRatio(videoDebugInfo.videoMovingMetrics?.ratio?.average);
    setDesiredBufferTime(videoDebugInfo.desiredBufferTime);
    setIdealLatency(videoDebugInfo.videoMovingMetrics?.latency?.low);
    setIdealBandwidth(videoDebugInfo.currentBitrate);
  }, [videoDebugInfo]);

  function getChart(chartType, chartValue, idealValue, text, unit, tooltip) {
    return chartValue &&
      chartValue > 0 &&
      getPossibleHandlers(chartType).includes(handler) ? (
      <div className={"chart-holder"}>
        <div className={"col-sm-4 chart"}>
          <LineChart type={chartType} value={chartValue} ideal={idealValue} />
        </div>
        <Tooltip title={tooltip}>
          <div className="chart-description">
            {`${text}: ${chartValue?.toFixed(2)} ${unit}`}
          </div>
        </Tooltip>
      </div>
    ) : null;
  }

  function getPossibleHandlers(type) {
    switch (type) {
      case "buffer":
        return ["HLS", "DASH"];
      case "bandwidth":
        return ["HLS", "DASH"];
      case "AvgPlayback:Download":
        return ["DASH"];
      case "rtt":
        return ["HLS", "DASH"];
      case "delayFromLiveEdge":
        return ["HLS", "DASH"];
      case "fps":
        return ["DASH"];
      default:
        return ["HLS", "DASH"];
    }
  }

  return handler ? (
    <Grid container className={"charts-wrapper"}>
      {getChart(
        "buffer",
        bufferLength,
        desiredBufferTime,
        "Actual Buffer Length",
        "s",
        ""
      )}
      {getChart(
        "rtt",
        rtt,
        idealLatency,
        "Network round-trip time",
        "s",
        "Average time taken for the response from the server since the request (for last 4 segments)"
      )}
      {getChart(
        "AvgPlayback:Download",
        avgMovingRatio,
        null,
        "Segment Playback Time : Total Download Time",
        "",
        "Ratio in average between video segment playback time to video segment download time"
      )}
      {getChart(
        "delayFromLiveEdge",
        delayFromLiveEdge,
        desiredBufferTime,
        "Delay from liveedge",
        "s",
        "Difference between live edge and current video time"
      )}
      {getChart(
        "bandwidth",
        bandwidth,
        idealBandwidth,
        "Bandwidth",
        "kpbs",
        ""
      )}
      {getChart("fps", fps, 25, "Frames per second", "", "")}
    </Grid>
  ) : null;
}
