import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./index.css";
import App from "./app/App";
import { SettingsProvider } from "./../src/hooks/SettingsContext";
import { LoadContextProvider } from "./../src/hooks/LoadContext";
import { PlayerProvider } from "./hooks/PlayerContext";
import { DebugInfoProvider } from "./hooks/DebugInfoContext";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { CMCDProvider } from "./hooks/CMCDContext";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1997C6",
    },
    secondary: {
      main: "#1BC98E",
    },
    inherit: {
      main: "#FFFFFF",
    },
  },
});

ReactDOM.render(
  <BrowserRouter>
    <MuiThemeProvider theme={theme}>
      <LoadContextProvider>
        <SettingsProvider>
          <PlayerProvider>
            <DebugInfoProvider>
              <CMCDProvider>
                <Switch>
                  <Route
                    path="(|/avvpl-new-debug-player)/:stream"
                    component={App}
                  />
                  <Route path="(|/avvpl-new-debug-player)" component={App} />
                </Switch>
              </CMCDProvider>
            </DebugInfoProvider>
          </PlayerProvider>
        </SettingsProvider>
      </LoadContextProvider>
    </MuiThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
