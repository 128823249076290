import React, { useCallback, useEffect, useState } from "react";
import { Line, Chart } from "react-chartjs-2";
import "chartjs-adapter-luxon";
import StreamingPlugin from "chartjs-plugin-streaming";

Chart.register(StreamingPlugin);

export function LineChart({ type, value, ideal }) {
  const [options, setOptions] = useState({});
  const [data, setData] = useState({});
  const [arr, setArr] = useState([]);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);

  useEffect(() => {
    const current = parseFloat(value);
    if (current > 0) {
      arr.push(current);
    }
    if (arr.length > 40) {
      arr.shift();
    }
    setMax(Math.max(...arr));
    if (Math.min(...arr) > 0) {
      setMin(Math.min(...arr));
    }
  }, [value, arr]);

  const onChartRefresh = useCallback(
    (chart) => {
      chart.data.datasets.forEach((dataset, index) => {
        let yValue;
        switch (index) {
          case 0:
            yValue = value;
            const yValues = dataset.data.map((data) => data.y);
            setArr(yValues);
            break;
          case 1:
            yValue = ideal;
            break;
          case 2:
            yValue = max;
            break;
          case 3:
            yValue = min;
            break;
          default:
            break;
        }
        dataset.data.push({
          x: Date.now(),
          y: yValue,
        });
      });
    },
    [value, ideal, max, min]
  );

  useEffect(() => {
    setData({
      datasets: [
        {
          label: type,
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          borderColor: "rgba(255, 255, 255, 1)",
          cubicInterpolationMode: "monotone",
          fill: true,
          data: [],
          radius: 0,
          borderWidth: 2,
        },
        {
          label: "ideal",
          borderColor: "rgba(0, 138, 93, 1)",
          cubicInterpolationMode: "monotone",
          fill: false,
          data: [],
          radius: 0,
          borderWidth: 2,
        },
        {
          label: "max",
          borderColor: "rgba(240, 156, 156, 1)",
          cubicInterpolationMode: "monotone",
          fill: false,
          data: [],
          radius: 0,
          borderWidth: 2,
        },
        {
          label: "min",
          borderColor: "rgba(240, 156, 156, 1)",
          cubicInterpolationMode: "monotone",
          fill: false,
          data: [],
          radius: 0,
          borderWidth: 2,
        },
      ],
    });
  }, [type]);

  useEffect(() => {
    setOptions({
      plugins: {
        legend: {
          display: false,
        },
        streaming: {},
      },
      scales: {
        x: {
          type: "realtime",
          realtime: {
            onRefresh: onChartRefresh,
            duration: 20000
          },
          ticks: {
            display: false,
          },
        },
        y: {
          ticks: {
            beginAtZero: true,
          },
        },
      },
    });
  }, [setOptions, value, onChartRefresh]);

  return <Line data={data} options={options} width={430} />;
}
