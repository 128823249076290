import React, { useState } from "react";

const LoadContext = React.createContext();

export function LoadContextProvider({ children }) {
  const [readyToLoad, setReadyToLoad] = useState(false);

  return (
    <LoadContext.Provider value={[{ readyToLoad }, { setReadyToLoad }]}>
      {children}
    </LoadContext.Provider>
  );
}

export default LoadContext;
